//
// cta.scss
//
//cta
.bg-cta {
    padding: 150px 0;
    @media (max-width: 768px) {
        padding: 100px 0;
    }
}

.play-btn {
    height: 78px;
    width: 78px;
    font-size: 30px;
    line-height: 76px;
    border-radius: 50%;
    display: inline-block;
    color: $white;
}

.play-icon {
    position: absolute;
    bottom: 50%;
    right: 0;
    left: 0;
    transform: translateY(50%);
    text-align: center;
    i {
        height: 75px;
        -webkit-text-stroke: 2px $primary;
        -webkit-text-fill-color: transparent;  
        width: 75px;
        font-size: 25px;
        line-height: 75px;
        display: inline-block;
        &:hover {
            -webkit-text-stroke: 2px $primary;
            -webkit-text-fill-color: $primary;
        }
    }
}

.watch-video {
    .play-icon-circle {
            height: 70px;
            width: 70px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            box-shadow: 0 0 45px rgba($primary, 0.9) !important;
        .icon {
            width: 22px;
            height: 22px;
        }
    }
}

//Video popup
// @media (max-width: 767px){
//     iframe {
//         width: auto !important;
//     }
// }